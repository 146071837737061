require([
    'jquery',
    'MbiHelper',
    'Vendor/flickity',
    'MbiImageSize'
], function(
    $,
    _,
    Flickity,
    mbiImageSize
    ) {
    'use strict'

/*

    setTimeout(function(){

    	mbiImageSize.init();

    },100)
*/

});

define("serviceIntro", function(){});

