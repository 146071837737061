require([
    'jquery',
    'MbiHelper',
    'MbiImageSize',
    'MbiMediaQuery',
], function(
    $,
    _,
    mbiImageSize,
    mbiMq
    ) {
    'use strict'

    /*============================================
    =          service container                 =
    ============================================*/

    $(document).on('click', '.js_openInfo', function(){

        if (!$(this).hasClass('active') && !$(this).hasClass('blubb')) {

            var $gridItem     = $(this);
            var $gridItems    = $('.grid__item');
            var id            = $gridItem.attr('data-infoId');
            var $infoFor      = $('.infoFor');
            var $rightInfoFor = $('.infoFor[data-infofor="' + id + '"]');
            var $infoDesks    = $('.info--desk');
            var $infoDesk     = $rightInfoFor.parent();

            $infoFor.removeClass('active');
            $gridItems.removeClass('active');
            $gridItems.removeClass('blubb');
            $infoDesks.removeClass('active');

            $gridItem.addClass('active');
            $infoDesk.addClass('active');
            $rightInfoFor.addClass('active');


                // console.log('before 400ms');
            setTimeout(function() {
                // console.log('after 400ms');
                $('html, body').animate({
                    scrollTop: $gridItem.offset().top+270
                }, 400);
            }, 600);
        }
    });

    function reArrangeInfo(){
        $('.grid__item').each(function(){
            var $gridItem = $(this);
            var id = $gridItem.attr('data-infoId');
            var $rightInfoFor = $('.infoFor[data-infofor="' + id + '"]');
            var $infoDeskWrapper = $gridItem.nextAll('.info--desk').first();
            if ('paw-' in mbiMq.mqArea) {
                $rightInfoFor.detach().appendTo($gridItem);
            } else {
                $rightInfoFor.detach().appendTo($infoDeskWrapper);

            }
        });
    }


    $(document).on('click', '.js_closeInfo', function(){

        var $infoDesks    = $('.info--desk');
        var $gridItems    = $('.grid__item');
        var $active    =    $('.grid__item.active');
        var $infoFor      = $('.infoFor');


        $infoDesks.removeClass('active');
        $gridItems.removeClass('active');

        $active.addClass('blubb');
        setTimeout(function() {
            $infoFor.removeClass('active');
        }, 400);



    });

    if($('.section-serviceGrid').length){
        reArrangeInfo();
        setTimeout(function(){

            mbiImageSize.init();

        },100)

        $(window).on('breakpoint',function(){
            reArrangeInfo();
        });
    }
    /*============================================
    =          service container end             =
    ============================================*/



    $(document).on('AjaxSwapDone', function(k,data){
        if(data){

            /*============================================
            =               serviceGrid                 =
            ============================================*/

            if($('.section-serviceGrid').length){
                reArrangeInfo();
                setTimeout(function(){

                    mbiImageSize.init();

                },100)

                $(window).on('breakpoint',function(){
                    reArrangeInfo();
                });
            }

            /*============================================
            =            serviceGrid end              =
            ============================================*/

        }
    });
});

define("serviceGrid", function(){});

