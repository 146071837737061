require([
    'jquery',
    'MbiHelper',
    'Vendor/flickity',
    'MbiImageSize',
    'MbiMediaQuery',
], function(
    $,
    _,
    Flickity,
    mbiImageSize,
    mbiMediaQuery
    ) {
    'use strict'

    setTimeout(function(){

        mbiImageSize.init();

    },100)

    var sendeGridSizes = {
        'phoneSmall' : 1,
        'phoneMedium' : 2,
        'phoneBig' : 2,
        'portrait' : 3,
        'landscape' : 4,
        'specialAfter' : 4,
        'desktop' : 5,
        'desktopWide' : 6
    };

    function sScrollToTarget(target){
        $('body, html').animate({
            scrollTop: target
            }, 300, function() {
        });
    }

    function setBoxHeight(that){

        var item = $(that).data('id');
        var itemBoxEl = $('#box'+item);
        //console.log(item,itemBoxEl,mbiMediaQuery.mqTag);
        var itemsInLine = sendeGridSizes[mbiMediaQuery.mqTag];
        //console.log(itemsInLine);
        var num = $(that).data('num');

        var currentLine = parseInt(((num-1)/itemsInLine));
        var insertAfterThisItemNum = ((currentLine*itemsInLine)+itemsInLine)-1;
        var insertAfterThisItem = $('.section-sendestrecken__item .item__card')[insertAfterThisItemNum];
        var insertAfterThisItemParent = $(insertAfterThisItem).parent();

        //console.log(itemsInLine,currentLine,insertAfterThisItemNum,insertAfterThisItem,insertAfterThisItemParent);

        $(itemBoxEl).insertAfter($(insertAfterThisItemParent));

        $(that).addClass('active');

        $(itemBoxEl).addClass('active');
        $(itemBoxEl).css({ 'position' : 'absolute', 'max-height':'none', 'opacity': '0' });

        var boxHeight = $(itemBoxEl).height();
        $(itemBoxEl).css({ 'position' : 'relative', 'max-height':'0', 'opacity': '1' });

        $(itemBoxEl).animate({
            'max-height' : boxHeight+200
        }, 300, function() {

        });

        var target = $(that).offset().top+200;
        sScrollToTarget(target);
    }

    function setCorrespondingIdBox(that){


        var itemsInLine = sendeGridSizes[mbiMediaQuery.mqTag];
        if(itemsInLine==1){
            $('.section-sendestrecken').addClass('addItem');
        } else {
            if($(that).data('num')<=itemsInLine){
                $('.section-sendestrecken').addClass('addItem');
            } else {
                $('.section-sendestrecken').removeClass('addItem');
            }
        }


        var allNum = $('.section-sendestrecken__item .item__card').length;

        $('.section-sendestrecken__item .item__card').removeClass('active');

        if($('.section-sendestrecken .item__content.active').length){

            $('.section-sendestrecken .item__content.active').animate({
                'max-height' : 0
            }, 300, function() {
                $('.section-sendestrecken .item__content.active').insertAfter($('.section-sendestrecken__item')[allNum-1]);
                $('.section-sendestrecken .item__content.active').removeClass('active');
                setBoxHeight(that);
            });

        } else {
            setBoxHeight(that);
        }

    }

    $(document).on('click','.section-sendestrecken__item .item__card',function(e){

        e.preventDefault();
        e.stopPropagation();

        if($(this).hasClass('active')){
            $('.section-sendestrecken .item__content.active').animate({
                'max-height' : 0
            }, 300, function() {
                var allNum = $('.section-sendestrecken__item .item__card').length;
                $('.section-sendestrecken .item__content.active').insertAfter($('.section-sendestrecken__item')[allNum-1]);
                $('.section-sendestrecken .item__content.active').removeClass('active');
                $('.section-sendestrecken__item .item__card').removeClass('active');

                $('.section-sendestrecken').removeClass('addItem');
            });
        } else {
            setCorrespondingIdBox(this);
        }

    });

    $(document).on('click','.js_close_sendestrecke',function(e){
        e.preventDefault();
        e.stopPropagation();

        var card = '#link'+$(this).parent().parent().data('id');
        var target = $(card).offset().top-46;
        sScrollToTarget(target);
        $(card).trigger('click');
    });

    $(window).on('breakpoint',function(){
        if($('.section-sendestrecken__item .item__card').hasClass('active')){
            setCorrespondingIdBox($('.section-sendestrecken__item .item__card.active'));
        }
    });
/*
    $(window).on('resize',function(){
        if($('.section-sendestrecken__item .item__card').hasClass('active')){
            setCorrespondingIdBox($('.section-sendestrecken__item .item__card.active'));
        }
    });
*/


    $(document).on('click','.section-sendestrecken .accordeon__item .title',function(e){
        $(this).parent().parent().find('.accordeon__item').each(function(k,el){
            $(el).removeClass('active');
        });
        $(this).parent().addClass('active');
        var ww = $(window).width();
        if(ww<=767){
            var that = this;
            setTimeout(function(){
                var target = $(that).offset().top-46;
                sScrollToTarget(target);
            },300);
        }
    });

    $(document).on('click','.js_showAllSendestrecken',function(e){
        $('.sendestreckenLoadmore').removeClass('limited');
    });
    $(document).on('click','.js_hideAllSendestrecken',function(e){
        if($('.section-sendestrecken .item__content').hasClass('active')){
            $('.section-sendestrecken .item__content.active').animate({
                'max-height' : 0
            }, 300, function() {
                var allNum = $('.section-sendestrecken__item .item__card').length;
                $('.section-sendestrecken .item__content.active').insertAfter($('.section-sendestrecken__item')[allNum-1]);
                $('.section-sendestrecken .item__content.active').removeClass('active');
                $('.section-sendestrecken__item .item__card').removeClass('active');

                $('.section-sendestrecken').removeClass('addItem');
            });
        }

        $('.sendestreckenLoadmore').addClass('limited');
        var target = $('.section-sendestrecken').offset().top-46;
        sScrollToTarget(target);
    });
});

define("sendestrecke", function(){});

