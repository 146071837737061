define('Ajax',[
	'jquery',
	'MbiHelper',
	'MbiMediaQuery',
	'MbiConfig',
	'MbiImageSize'
], function(
	$,
	_,
	mbiMq,
	mbiConfig,
	mbiImageSize
) {
	'use strict';

/*
	Ajax Handler for loading pages via Ajax Get Request.
	Works with every Link to an internal page (i.e. Menu Link)
	Href necessary
	simply add class 'js_ajax' for required workflow

	See Ajax.php sample page for infos how to return content
	from a page.

*/
	var module = {
		ajaxhandler: '.js_ajax',
		noajax: 'js_no_ajax',
		fadeout: 'is_fading_out',
		contentContainer: '.site',

		init: function(){
			$(document).on('click', module.ajaxhandler, function(e) {
				if(!$(this).hasClass(module.noajax)) {
					e.preventDefault();
					if($(this).attr('href')) {
						var $url = $(this).attr('href');
					} else {
						$url = $(this).attr('data-href');
					}
					var $name = $(this).attr('data-name');
					$(module.contentContainer).addClass(module.fadeout);
					if($(this).parent().hasClass('menu__item')) {
						$('.menu__item--active').removeClass('menu__item--active menu__item--current');
						$('.menu__entry--active').removeClass('menu__entry--active');
						$(this).parent().addClass('menu__item--active menu__item--current');
						$(this).parent().addClass('menu__entry--active');
						// $(this).addClass('menu__item--current menu__item--active');
						// $(this).find('a').addClass('menu__entry--active');
					}

					if($url != location.href) {
						$.ajax({
							url: $url,
							dataType: 'json',
							data: {},
						})
						.done(function(data) {
							history.pushState({
								'url': 			data.url,
								'title': 		data.title,
								'origin': 		location.href,
								'content': 		data.content,
								'activeMenu': $name,
								'mode': 		'history'
							}, data.title, data.url);
							console.log(data.content);
							// setTimeout(function(){
								$(module.contentContainer).html(data.content);
								$(module.contentContainer).removeClass('is_fading_out');
								$(document).trigger('AjaxSwapDone');
								document.title = data.title;
								mbiImageSize.init();
								// initAll();

							// }, 100);
						})
						.fail(function() {
							location.href = $url;

						})
						.always(function() {
						});
					}
				}
			});

			$(document).on('AjaxSwapDone', function(){

				setTimeout(function(){
					module.initAll();
				},1);
			});


			$(window).on('popstate', function(event) {

			    $(module.contentContainer).addClass('is_fading_out');
			    var content = (window.event.state.content);
				$('.menu__item').removeClass('menu__item--active').removeClass('menu__item--current');
				$('.menu__entry').removeClass('menu__entry--active').removeClass('menu__entry--current');
				$('.menu__entry--'+window.event.state.activeMenu).addClass('menu__entry--active').addClass('menu__entry--current');
				$('.menu__item--'+window.event.state.activeMenu).addClass('menu__item--active').addClass('menu__item--current');
				setTimeout(function(){
					$(module.contentContainer).html(content);
					$(module.contentContainer).removeClass('is_fading_out');
					$(document).trigger('AjaxSwapDone');
				}, 1);
			});

			history.replaceState({
				'url': 			location.href,
				'title': 		document.title,
				'origin': 		location.href,
				'content': 		$(module.contentContainer).html(),
				'activeMenu': $('.menu__entry--current').attr('data-name'),
				'mode': 		'history',
			}, document.title, location.href);
		},
		initAll: function(){
			mbiImageSize.init();
			//if you need a swiper or isotope or anything else,
			//initialize everything HERE
		}
	};


	module.init();
	mbiConfig.modules.Ajax = {
		'init': 	true,
	};

	// -----------------------------------------------------------------

	return module;

});
