require([
    'jquery',
    'MbiHelper',
    'MbiImageSize',
    //'project/Vendor/debounce',
], function(
    $,
    _,
    mbiImageSize
    ) {
    'use strict'

    /*============================================
    =               three elements               =
    ============================================*/
    function setChildHighestHeight(){
        var slideHeight = 0;
        $('.square__item').each(function(index){
            if ($(this).outerHeight() > slideHeight) {
                slideHeight = $(this).outerHeight();
            }
        });
        $('.square__item').css('height', slideHeight);
    }

    if($('.square__item').length){
        setChildHighestHeight();
        $(window).resize(function(){
            $('.square__item').css('height', '');
            setChildHighestHeight();
        });
    }

    /*============================================
    =            three elements end              =
    ============================================*/


    $(document).on('AjaxSwapDone', function(k,data){
        if(data){

            /*============================================
            =               three elements               =
            ============================================*/

            if($('.square__item').length){
                setChildHighestHeight();
                $(window).resize(function(){
                    $('.square__item').css('height', '');
                    setChildHighestHeight();
                });
            }

            /*============================================
            =            three elements end              =
            ============================================*/

        }
    });
/*
    setTimeout(function(){

    	mbiImageSize.init();

    },100)
*/
});
/*
(function(window,undefined){
  '$:nomunge';
  var $ = window.jQuery || window.Cowboy || ( window.Cowboy = {} ),
    jq_throttle;
  $.throttle = jq_throttle = function( delay, no_trailing, callback, debounce_mode ) {
    var timeout_id,
      last_exec = 0;
    if ( typeof no_trailing !== 'boolean' ) {
      debounce_mode = callback;
      callback = no_trailing;
      no_trailing = undefined;
    }

    function wrapper() {
      var that = this,
        elapsed = +new Date() - last_exec,
        args = arguments;

      function exec() {
        last_exec = +new Date();
        callback.apply( that, args );
      };

      function clear() {
        timeout_id = undefined;
      };

      if ( debounce_mode && !timeout_id ) {
        exec();
      }

      timeout_id && clearTimeout( timeout_id );

      if ( debounce_mode === undefined && elapsed > delay ) {
        exec();
      } else if ( no_trailing !== true ) {
        timeout_id = setTimeout( debounce_mode ? clear : exec, debounce_mode === undefined ? delay - elapsed : delay );
      }
    };
    if ( $.guid ) {
      wrapper.guid = callback.guid = callback.guid || $.guid++;
    }
    return wrapper;
  };
  $.debounce = function( delay, at_begin, callback ) {
    return callback === undefined
      ? jq_throttle( delay, at_begin, false )
      : jq_throttle( delay, callback, at_begin !== false );
  };

})(this);
*/
;
define("threeElement", function(){});

