require([
    'jquery',
    'MbiHelper',
    'Vendor/flickity',
    'MbiImageSize'
], function(
    $,
    _,
    Flickity,
    mbiImageSize
    ) {
    'use strict'
/*
    if($('.section-gallery .swiper').length){
        var blogflkty = new Flickity( '.section-gallery .swiper', {
            cellSelector: '.slide',
            pageDots: true,
            wrapAround: true,
            prevNextButtons: true,
            imagesLoaded: true
        });

        setTimeout(function(){

        	mbiImageSize.init();

        },100)

    }
*/
});

define("brands", function(){});

