define('jobs',[
	'jquery',
	'MbiHelper',
	'MbiMediaQuery',
	'MbiConfig'
], function(
	$,
	_,
	mbiMq,
	mbiConfig
) {
	'use strict';

/*
	if(_.exists('.section-jobs .accordion')) {
		require(['project/jobs'], function(mbiAccordion) {

			$('.accordion').each(function() {
				var id = $(this).attr('id'),
				data = $(this).data(),
				args = {
					offset: 		0.25,
					speed: 			1500,
					firstActive: 	false,
					scrollOnOpen: 	data.accordionscrollOnOpen,
					scrollOnClose: 	data.accordionscrollOnClose,
					scrollAlign: 	data.accordionScrollAlign
				};

				mbiAccordion.createAccordion(id, args);

			});
			mbiAccordion.init();

		});
	}
*/
});
