define('jobpool',[
	'jquery',
	'MbiHelper',
	'MbiMediaQuery',
	'MbiConfig',
	'isotope'
], function(
	$,
	_,
	mbiMq,
	mbiConfig,
	Isotope
) {
	'use strict';

    /*============================================
    =              jobpool stuff                 =
    ============================================*/
    var isoPeoples;
    var theLayoutMode = 'masonry';
    var initialFilter = '*';
    //var theLayoutMode = 'packery';
    if($('.peopleIntro').length){

        isoPeoples = new Isotope('.peoplePool',{
            itemSelector: '.peopleItem',
            layoutMode: theLayoutMode,
            filter: initialFilter
        });
    }


    var setFiltersPeople = [];

    $(document).on('click','.peopleFilters .filter',function(e){

        setFiltersPeople = [];
        if($(this).hasClass('active')){
            $('.peopleFilters .filter').removeClass('active');
        } else {
            $('.peopleFilters .filter').removeClass('active');
            setFiltersPeople.push($(this).data('class'));
            $(this).addClass('active');
        }

        var filter = '*';
        if(setFiltersPeople.length>0){
            filter = '.'+setFiltersPeople.join(".");
        }

        var wfilter = filter;
        isoPeoples.arrange({ filter: wfilter });

        setTimeout(function(){
            isoPeoples.arrange();
        },200);
    });

    $(document).on('click','.js_showAllPeoplePool',function(e){
        $('.peoplePoolLoadmore').removeClass('limited');
        isoPeoples.arrange();
    });

    $(document).on('click','.js_hideAllPeoplePool',function(e){
        $('.peoplePoolLoadmore').addClass('limited');
        var target = $('.peopleIntro').offset().top-46;
        $('body, html').animate({
            scrollTop: target
            }, 300, function() {
        });
    });

    $(document).on('click','.peopleFilters .filter',function(e){

        var items = $('.peoplePool');
        items.parent().removeClass('initialFilterSet');
        items.parent().addClass('removeLoader');

        if(items.length){
            var wH2 = ($(window).height()/2)+60;
            var target = items.offset().top-wH2;
            $('body, html').animate({
                scrollTop: target
                },300, function() {
            });
        }
    });

    $(document).on('AjaxSwapDone', function(k,data){
        if(data){
            if($('.peopleIntro').length){

                isoPeoples = new Isotope('.peoplePool',{
                    itemSelector: '.peopleItem',
                    layoutMode: theLayoutMode,
                    filter: initialFilter
                });
            }
        }
    });

});
