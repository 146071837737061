require([
    'jquery',
    'MbiHelper',
    'Vendor/flickity',
    'MbiImageSize'
], function(
    $,
    _,
    Flickity,
    mbiImageSize
    ) {
    'use strict'
/*
    if($('.section-blogswiper .swiper').length){
        var blogflkty = new Flickity( '.section-blogswiper .swiper', {
            cellSelector: '.slide',
            pageDots: true,
            wrapAround: true,
            prevNextButtons: false,
            //imagesLoaded: true,
        });

        setTimeout(function(){

        	mbiImageSize.init();

        },100)

    }
*/


    if($('.section-blogswiper .swiper').length){
        var blogflkty = new Flickity( '.section-blogswiper .swiper', {
            cellSelector: '.slide',
            pageDots: true,
            wrapAround: true,
            prevNextButtons: false,
            //imagesLoaded: true,
            /*
            arrowShape: {
                x0: 30,
                x1: 60, y1: 25,
                x2: 60, y2: 20,
                x3: 60
            }
            */
        });

        setTimeout(function(){

            mbiImageSize.init();

        },100)

    }

    $(document).on('AjaxSwapDone', function(k,data){
        if(data){
            if($('.section-blogswiper .swiper').length){
                var blogflkty = new Flickity( '.section-blogswiper .swiper', {
                    cellSelector: '.slide',
                    pageDots: true,
                    wrapAround: true,
                    prevNextButtons: false,
                    //imagesLoaded: true,
                    /*
                    arrowShape: {
                        x0: 30,
                        x1: 60, y1: 25,
                        x2: 60, y2: 20,
                        x3: 60
                    }
                    */
                });

                setTimeout(function(){

                    mbiImageSize.init();

                },100)

            }
        }
    });

});

define("blogSwiper", function(){});

