require([
    'jquery',
    'MbiHelper',
    'Vendor/flickity',
    'MbiImageSize',
    'waypoints',
    'MbiMediaQuery',
    'Vendor/debounce',
], function(
    $,
    _,
    Flickity,
    mbiImageSize,
    waypoints,
    mbiMq
    ) {
    'use strict'


    /*=======================================
    =               swipers                 =
    =======================================*/
    if($('.section-teaserBox .swiper').length){
        $('.section-teaserBox .swiper').each(function(k,el){

        //$(window).on('load',function(){
            var orientation = $(el).data('orientation');

            var blogflkty = new Flickity( el, {
                cellSelector: '.slide',
                pageDots: true,
                wrapAround: true,
                prevNextButtons: true,
                imagesLoaded: true,
                resize: true
            });
        //});

        });
    }

//console.log('doubleTeaser called');

    /*=======================================
    =            vertical scroll            =
    =======================================*/
    var totalHeight = 0;
    var slideHeight = 0;

    function initVerticalScroll(){
        totalHeight = 0;
        slideHeight = 0;
        var len = 0;
        var $slideItems = $('.swiper--vertical').children();
        len = $slideItems.length;
        $slideItems.each(function(index){

            var $slide = $(this);
            var $videoW = $slide.siblings('.videoWrapper2').children();
            var $slides = $slide.parent().children();
            var $otherBox = $slide.parent().parent().siblings();
                slideHeight = $slide.outerHeight(true);
            var slideWidth = $slide.outerWidth(true);

            totalHeight = totalHeight + slideHeight;

            if (index == len - 1) {
                //console.log(totalHeight, 'totalHeight');
                //console.log(slideHeight, 'slideHeight');
                //console.log('##############');

                $otherBox.css('height', slideHeight);
                $(this).parent().parent().parent().css('height', totalHeight);
                $slides.css('height', slideHeight);
                $slides.css('width', slideWidth);
                $('.swiper--vertical .slide').removeClass('posAbs');

            }
        });

        // console.log(totalHeight);
    }


    var waypointTop = '';
    var waypointBottom = '';
    function initWaypoint(){
        waypointTop = new Waypoint({
            element: document.getElementsByClassName('swiper--vertical'),
            handler: function(direction) {
                var slider  = this.element;
                var $slider = $(slider);
                var $parent = $slider.parent();

                var $videoW = $slider.children('.videoWrapper');
                var $video  = $slider.children('.videoWrapper').children();

                if (direction == 'down') {
                    $parent.siblings().addClass('is_fixed');
                    $videoW.addClass('is_fixed');
                    $video.get(0).play();
                } else {
                    $parent.siblings().removeClass('is_fixed');
                    $videoW.removeClass('is_fixed');
                    $video.get(0).pause();
                }
            },
            // offset: 'bottom-in-view'
        });

        waypointBottom = new Waypoint({
            element: document.getElementsByClassName('swiper--vertical'),
            handler: function(direction) {
                // console.log('trigger?');
                var slider  = this.element;
                var $slider = $(slider);
                var $parent = $slider.parent();

                var $videoW = $slider.children('.videoWrapper');
                var $video  = $slider.children('.videoWrapper').children();

                if (direction == 'down') {
                    $parent.siblings().removeClass('is_fixed');
                    $parent.siblings().addClass('is_bottom');

                    $videoW.addClass('is_bottom');
                    $videoW.removeClass('is_fixed');
                    $video.get(0).pause();

                } else {
                    $parent.siblings().addClass('is_fixed');
                    $parent.siblings().removeClass('is_bottom');

                    $videoW.addClass('is_fixed');
                    $videoW.removeClass('is_bottom');
                    $video.get(0).play();
                }
            },
            // offset: totalHeight
            offset: -(totalHeight - slideHeight)
        });
    }


    function destroyVerticalSlider(){
        $('.videoWrapper').removeClass('is_fixed').removeClass('is_bottom');
        $('.boxItem').removeClass('is_fixed').removeClass('is_bottom');
        Waypoint.disableAll(); // Waypoint.enableAll();


        var $slideItems = $('.swiper--vertical').children();
        var len = 0;
        len = $slideItems.length;

        $slideItems.each(function(index){

            var $slide = $(this);
            var $videoW = $slide.siblings('.videoWrapper2').children();
            var $slides = $slide.parent().children();
            var $otherBox = $slide.parent().parent().siblings();

            if (index == len - 1) {
                $otherBox.css('height', '');
                $(this).parent().parent().parent().css('height', '');
                $slides.css('height', '');
                $slides.css('width', '');
                $('.swiper--vertical .slide').removeClass('posAbs');
            }

        });
    }

    if ($('.swiper--vertical').length > 0) {

        if ('specialAfter+' in mbiMq.mqArea) {
            initVerticalScroll();
            initWaypoint();
        }

        $(window).resize( function(){

            if ('specialAfter+' in mbiMq.mqArea) {
                $('.swiper--vertical .slide').css('width', 'auto');
                $('.swiper--vertical .slide').addClass('posAbs');
                destroyVerticalSlider();
                setTimeout(function() {

                    initVerticalScroll();
                    Waypoint.enableAll();
                    // Waypoint.refreshAll();
                }, 100);
            } else {

                destroyVerticalSlider();
            }


        });

    }
    /*=======================================
    =            vertical scroll            =
    =======================================*/


    $(document).on('AjaxSwapDone', function(k,data){
        if(data){

            /*============================================
            =               job container               =
            ============================================*/
            if($('.jobContainer').length){
                $('.jobContainer').each(function(k,el){
                    $(el).insertAfter($('.site'));
                });
            }


            if($.inArray("page-slug-people",data['bodyClass'])>-1){
                var section = getUrlParameter('section');
                if(section){
                    scrollToJobs();
                }
            }



            if($('.section-teaserBox .swiper').length){
                $('.section-teaserBox .swiper').each(function(k,el){

                //$(window).on('load',function(){
                    var orientation = $(el).data('orientation');

                    var blogflkty = new Flickity( el, {
                        cellSelector: '.slide',
                        pageDots: true,
                        wrapAround: true,
                        prevNextButtons: true,
                        imagesLoaded: true,
                        resize: true
                    });
                //});

                });
            }


            /*=======================================
            =            vertical scroll            =
            =======================================*/
            if ($('.swiper--vertical').length > 0) {

                if ('specialAfter+' in mbiMq.mqArea) {
                    initVerticalScroll();
                    initWaypoint();
                }

                $(window).resize( function(){

                    if ('specialAfter+' in mbiMq.mqArea) {

                        $('.swiper--vertical .slide').css('width', 'auto');
                        $('.swiper--vertical .slide').addClass('posAbs');
                        destroyVerticalSlider();
                        setTimeout(function() {

                            initVerticalScroll();
                            Waypoint.enableAll();
                            // Waypoint.refreshAll();
                        }, 100);
                    } else {

                        destroyVerticalSlider();
                    }


                });

            } else {
                destroyVerticalSlider();
            }
            /*============================================
            =            vertical scroll end             =
            ============================================*/

        }
    });

});

define("doubleTeaser", function(){});

