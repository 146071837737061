require([
    'jquery',
    'MbiHelper',
    'MbiImageSize'
], function(
    $,
    _,
    mbiImageSize
    ) {
    'use strict'

    setTimeout(function(){

    	mbiImageSize.init();

    },100)


    // var heights = new Object();
    // $('.square__item').each(function(index){
    //     // if ($(this).hasClass('item--noEmail')) {
    //         heights.index = $(this).height();
    //     // }
    // });

    // console.log(heights);

});

define("borderText", function(){});

