define('MbiImageSize',[
	'jquery',
	'MbiHelper',
	'MbiConfig'
], function(
	$,
	_,
	mbiConfig
) {
	'use strict';

	// ---------------------------------------------------------------
	// IMAGE--COVER
	// ---------------------------------------------------------------
	var module = {

		imagesSelector:			'.image--cover',
		imageParentSelector:	'[class~="image:parent"]',
		swiperSelector:			'.swiper',

		imageWidthClass: 		'image--fitWidth',
		imageHeightClass: 		'image--fitHeight',

		init: function() {

			module.checker();

		},
		checker: function(items) {

			var $images;

			if(typeof items !== 'undefined') {
				if(typeof items == 'string') {
					$images = $(items);
				} else if(items instanceof jQuery) {
					$images = items;
				}
			} else {
				$images = $(module.imagesSelector);
			}

			$images.each(function() {

				var image = $(this)[0];
				var $image = $(this);
				var $parent = $image.parents(module.imageParentSelector);

				var imageWidth = image.naturalWidth;
				var imageHeight = image.naturalHeight;

				if(typeof(imageWidth)==='undefined' || typeof(imageHeight)==='undefined'){
					imageWidth = parseInt($image.attr('width'));
					imageHeight = parseInt($image.attr('height'));
				}
				var imageRatio = imageWidth/imageHeight;

				var parentWidth = $parent.innerWidth();
				var parentHeight = $parent.innerHeight();
				var parentRatio = parentWidth/parentHeight;

				$image.removeClass(module.imageHeightClass + ' ' + module.imageWidthClass);

				var data = $image.data();

				var heightClass = module.imageHeightClass;
				if('imageHeightSelector' in data) {
					heightClass = data.imageHeightSelector;
				}
				var widthClass = module.imageWidthClass;
				if('imageWidthSelector' in data) {
					widthClass = data.imageWidthSelector;
				}

				if(imageRatio >= parentRatio) {
					$image.addClass(heightClass);
				} else {
					$image.addClass(widthClass);
				}

			});

		}

	};

	// ----------------------------------------------------------------
	// CONFIG FOR MBICSS v2
	// ----------------------------------------------------------------
	if(!!mbiConfig.jsMbiCssVersion && mbiConfig.jsMbiCssVersion === 2) {
		module.imagesSelector =				'.js_image-cover';
		module.imageParentSelector =		'.o_image-parent';
		module.imageWidthClass = 			'o_image--fit-width';
		module.imageHeightClass = 			'o_image--fit-height';
		module.swiperSelector =				'.js_swiper';
	}

	if(_.exists(module.imagesSelector) || _.exists(module.swiperSelector)) {

		module.init();

		$(window).on('load', function() {
			module.checker();
		});
		
		$(window).on('resize', function() {
			module.checker();
		});

	}

	mbiConfig.modules.MbiImageSize = {
		'init': 	true,
		'version':	'2.0.0'
	};

	return module;

});
